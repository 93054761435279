/// <reference path="../../../node_modules/handlebars/types/index.d.ts" />

namespace Templates
{
    export const getParser = (template: JQuery | Page.GetJQueryOptions): HandlebarsTemplateDelegate<any> =>
    {
        const $template = Page.getJQuery(template);
        const templateHTML = $template.html();
        return Handlebars.compile(templateHTML);
    }

    export const parse = ($template: JQuery, value: any): string =>
    {
        return getParser($template)(value);
    }

    const shortDateFormatOptions: Intl.DateTimeFormatOptions = { day: "2-digit", month: "2-digit", year: "numeric", timeZone: 'UTC' };

    const formatDate = (value: string): string =>
    {
        let formattedValue: string = null;
        if (value != null && value != '')
        {
            const date = new Date(value);
            formattedValue = date.toLocaleDateString("en-US", shortDateFormatOptions);
        }

        return formattedValue;
    }

    const formatDateTime = (value: string): string =>
    {
        let formattedValue: string = null;
        if (value != null && value != '')
        {
            const date = new Date(value);
            formattedValue = date.toLocaleDateString('en', shortDateFormatOptions) + " " + date.toLocaleTimeString('en', { hour: '2-digit', minute: '2-digit' });
        }

        return formattedValue;
    }

    const formatBool = (value: boolean): string =>
    {
        let formattedValue: string = null;

        if (value)
        {
            formattedValue = "Yes";
        }
        else
        {
            formattedValue = "No";
        }

        return formattedValue;
    }

    const formatCurrency = (value: number | string): string =>
    {
        let formattedValue: string = null;
        if (value != null && value != '')
        {
            var formater = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            });

            formattedValue = formater.format(<number>value);
        }

        return formattedValue;
    }

    const formatPhoneNumber = (value: string): string =>
    {
        let formattedValue: string;
        if (value != null && value != '')
        {
            const numbersFromValue = value.replace(/[^\d]/g, "");

            if (numbersFromValue.length == 10)
            {
                //reformat and return phone number
                formattedValue = numbersFromValue.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
            }
            else
            {
                formattedValue = value;
            }
        }
        else
        {
            formattedValue = '';
        }

        return formattedValue;
    }

    const checked = (value: boolean): string =>
    {
        let formattedValue: string = null;
        if (value)
        {
            formattedValue = "checked";
        }

        return formattedValue;
    }

    const formatStringArray = (value: string[]): string =>
    {
        let formattedValue: string = null;
        if (value)
        {
            formattedValue = value.join(", ");
        }

        return formattedValue;
    }

    const formatDescription = (descriptions: ValueDescription[], value: string | number): string =>
    {
        let formattedValue: string = null;

        if (value != null && value != '')
        {
            const filteredDescriptions = descriptions.filter(et => et.value == value);
            if (filteredDescriptions.length)
            {
                formattedValue = descriptions.filter(et => et.value == value)[0].description;
            }
            else
            {
                formattedValue = value.toString();
            }
        }

        return formattedValue;
    }

    const formatDescriptionStringArray = (descriptions: ValueDescription[], value: string[]): string =>
    {
        let formattedValue: string = null;

        if (value)
        {
            let formattedValues = value.map((v) => formatDescription(descriptions, v));
            formattedValue = formattedValues.join(", ");
        }

        return formattedValue;
    }

    const formatFileSize = (value: number): string =>
    {
        let formattedValue: string = null;

        const units: { min: number, max: number, notation: string }[] =
            [
                { min: 1, max: 1024, notation: 'B' },
                { min: 1024, max: 1048576, notation: 'KB' },
                { min: 1048576, max: 1073741824, notation: 'MB' },
                { min: 1073741824, max: 1099511627776, notation: 'GB' }
            ];

        if (value == 0)
        {
            formattedValue = '0 B';
        }
        else
        {
            let unit = units.filter((u) => u.min <= value && u.max > value)[0];
            formattedValue = `${(value / unit.min).toFixed(2)} ${unit.notation}`;
        }

        return formattedValue;
    }

    export const load = () =>
    {
        Handlebars.registerHelper('formatDate', formatDate);
        Handlebars.registerHelper('formatDateTime', formatDateTime);
        Handlebars.registerHelper('formatBool', formatBool);
        Handlebars.registerHelper('formatCurrency', formatCurrency);
        Handlebars.registerHelper('checked', checked);
        Handlebars.registerHelper('formatStringArray', formatStringArray);
        Handlebars.registerHelper('formatDescription', formatDescription);
        Handlebars.registerHelper('formatFileSize', formatFileSize);
        Handlebars.registerHelper('formatDescriptionStringArray', formatDescriptionStringArray);
        Handlebars.registerHelper('formatPhoneNumber', formatPhoneNumber);
    }

    export interface UpdateContainerOptions
    {
        requestData?: any,
        data?: any,
    }

    export const updateContainer = ($templateContainer: JQuery, options: UpdateContainerOptions): void =>
    {
        if (options.data)
        {
            updateTemplateContainer(options.data);
        }
        else
        {
            Site.get({ action: $templateContainer.getAction(), url: $templateContainer.data("url"), data: options.requestData, onSuccess });
        }

        function onSuccess(result: any)
        {
            updateTemplateContainer(result);
        }

        function updateTemplateContainer(data: any): void
        {
            const html = Templates.parse($templateContainer.getJQuery({ containerFor: "template" }), data);

            const $contentContainer = $templateContainer.getJQuery({ containerFor: "content" });

            $contentContainer.html(html);
        }
    }

    export const clearContainer = ($templateContainer: JQuery) =>
    {
        const $contentContainer = $templateContainer.getJQuery({ containerFor: "content" });

        $contentContainer.html('');
    }

    export interface ValueDescription
    {
        value: string;
        description: string;
    }
}

Templates.load();